import React, { useEffect } from "react";
import { get } from "lodash-es";
import { v4 as uuidv4 } from "uuid";
import { IntlProvider } from "react-intl";
import invariant from "invariant";
import messages from "./locale/english";
import ErrorList from "./components/ErrorList";
import ErrorHandlingContext from "./ErrorHandlingContext.jsx";
import apiRequest from "./api/api";
import FloatingSpinner from "./components/FloatingSpinner";
import { CanceledError } from "axios";

const AppWrapper = ({ Component, rootElement }) => {
  const [errors, setErrors] = React.useState([]);

  const reportError = (error, title) => {
    if (typeof error === "string") {
      const newErrors = [
        ...errors,
        { type: "danger", message: error, guid: uuidv4(), title },
      ];
      setErrors(newErrors);
    } else if (error instanceof Error) {
      const message = get(error, "response.data.message", error.message);
      const newErrors = [
        ...errors,
        { type: "danger", message, guid: uuidv4(), title },
      ];
      setErrors(newErrors);
    } else {
      console.log("Unable to handle error of type", typeof error);
    }
  };

  const reportMessage = (message, title, level = "success") => {
    setTimeout(() => {
      const newErrors = [
        ...errors,
        { type: level, message, title, guid: uuidv4() },
      ];
      setErrors(newErrors);
    }, 1000);
  };

  async function safeRequest({ fetchCall, url, message, title }) {
    try {
      await fetchCall({ url });
    } catch (exception) {
      if (exception instanceof CanceledError) {
        // pass
      } else {
        console.log("exception", exception);
        reportError(message || exception, title);
      }
    }
  }

  async function safePost({
    call,
    url,
    data,
    callback,
    errorTitle,
    handleError,
  }) {
    try {
      await call({ url, data });
      if (callback) {
        callback();
      }
    } catch (exception) {
      const message = get(exception, "response.data.message", null);
      reportError(message || exception, errorTitle);
      if (handleError) {
        if (exception?.code !== "ERR_CANCELED") {
          handleError(exception);
        }
      }
    }
  }

  const removeError = (guid) => {
    setErrors(errors.filter((e) => e.guid !== guid));
  };

  const directApiRequest = ({
    method,
    url,
    data,
    timeout,
    handleResponse,
    handleError,
    callback,
  }) => {
    invariant(url, "URL not specified");
    apiRequest({ method, url, data, timeout })
      .then((response) => handleResponse(response))
      .catch((exception) => {
        reportError(exception);
        if (callback) {
          callback(exception);
        }
        if (handleError) {
          handleError(exception);
        }
      });
  };

  const extractFormErrors = (exception) => {
    const responseCode = get(exception, "response.status", 0);
    if (responseCode === 422) {
      const fieldErrors = get(exception, "response.data.field_errors", {});
      const nonFieldErrors = get(
        exception,
        "response.data.non_field_errors",
        [],
      );
      return { fieldErrors, nonFieldErrors };
    }
    return { fieldErrors: {}, nonFieldErrors: [] };
  };

  useEffect(() => {
    if (rootElement) {
      const element = document.getElementById("rootElement");
      if (element) {
        element.classList.remove("react-placeholder");
      }
    }
  }, [rootElement]);

  return (
    <React.StrictMode>
      <IntlProvider locale="en" messages={messages}>
        <ErrorHandlingContext.Provider
          value={{
            errors,
            reportError,
            safeRequest,
            safePost,
            directApiRequest,
            reportMessage,
            extractFormErrors,
          }}
        >
          <React.Suspense fallback={<FloatingSpinner />}>
            <Component config={`${rootElement}-config`} />
          </React.Suspense>
        </ErrorHandlingContext.Provider>
        <ErrorList
          element={`${rootElement}-errors`}
          errors={errors}
          removeError={removeError}
        />
      </IntlProvider>
    </React.StrictMode>
  );
};

export default AppWrapper;
