import Axios from "axios";
import HmacSHA256 from "crypto-js/hmac-sha256.js";
import { configure } from "axios-hooks";
import Cookies from "js-cookie";
import { getQueryArgs } from "../utils";

const source = Axios.CancelToken.source();

const requestSigningInterceptor = (config) => {
  let url = config.url;
  const n = url.indexOf("?");
  url = url.substring(0, n !== -1 ? n : url.length);

  let payload = "";
  const contentType = config.headers["Content-Type"];
  if (contentType && contentType.toLowerCase() === "application/json") {
    payload = JSON.stringify(config.data);
  }
  const message = [payload, url].join(":");
  config.headers["X-Signature"] = HmacSHA256(
    message,
    import.meta.env.VITE_API_SECRET,
  ).toString();

  return config;
};

const axios = Axios.create({
  baseURL: import.meta.env.VITE_SERVER_API_URL,
  timeout: 60000,
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    "X-Apikey": import.meta.env.VITE_API_KEY,
    "X-CSRFTOKEN": Cookies.get("csrftoken"),
  },
  cancelToken: source.token,
});

axios.interceptors.request.use(requestSigningInterceptor);

configure({ axios });

// const adapter = hmacAdapter(axios);
// axios.defaults.adapter = adapter;

const apiRequest = ({ method, url, data }) => {
  if (method === "GET") {
    const queryString = data ? getQueryArgs(data) : "";
    return axios.request({ method, url: `${url}${queryString}` });
  }
  return axios.request({ method, url, data });
};

const postData = (url, data, callback) => {
  apiRequest({ method: "POST", url, data })
    .then((response) => callback(response))
    .catch(() => callback(null));
};

export { postData };

export default apiRequest;
