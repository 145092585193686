const IconTest = () => {
  return (
    <>
      <p>
        Hello <span className="fab fa-twitter" />
      </p>
      <p>
        <span className="far fa-shopping-cart" />
      </p>
      <p>
        <span className="fas fa-times-circle" />
      </p>
      <p>
        <span className="far fa-arrow-left" />
      </p>
      <p>
        <span className="far fa-arrow-right" />
      </p>
      <p>
        <span className="far fa-ellipsis" />
      </p>
    </>
  );
};

export default IconTest;
