const installAccordionToggles = () => {
  document.querySelectorAll(".accordion").forEach((accordion) => {
    accordion.querySelectorAll(".accordion-button").forEach((button) => {
      button.addEventListener("click", (e) => {
        const accordionItem = e.target.closest(".accordion-item");
        const accordionCollapse = accordionItem.querySelector(
          ".accordion-collapse",
        );
        if (accordionCollapse.classList.contains("collapse")) {
          accordion
            .querySelectorAll(".accordion-collapse")
            .forEach((item) => item.classList.add("collapse"));
          accordionCollapse.classList.remove("collapse");
        } else {
          accordionCollapse.classList.add("collapse");
        }
      });
    });
  });
};

const installTabToggles = () => {
  document.querySelectorAll(".nav-tabs").forEach((navTab) => {
    navTab.querySelectorAll(".nav-link").forEach((navLink) => {
      navLink.addEventListener("click", (e) => {
        e.preventDefault();

        // Remove 'active' class from all tabs and tab contents
        navTab
          .querySelectorAll(".nav-link")
          .forEach((link) => link.classList.remove("active"));
        const tabContents = document.querySelectorAll('[id^="tabcontent_"]');
        tabContents.forEach((content) => {
          content.classList.remove("active", "show");
        });

        // Add 'active' class to clicked tab
        navLink.classList.add("active");

        // Activate the corresponding tab content
        const tabContentId = `tabcontent_${navLink.id.substring("tab_".length)}`;

        const tabContentElement = document.getElementById(tabContentId);
        if (tabContentElement) {
          tabContentElement.classList.add("active", "show");
        }
      });
    });
  });
};

const installBootstrapHandlers = () => {
  installAccordionToggles();
  installTabToggles();
};

export default installBootstrapHandlers;
