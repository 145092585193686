import { toNumber } from "lodash-es";

const convertToQueryValue = (item) => {
  if (typeof item === "boolean") {
    if (item) {
      return 1;
    }
    return null;
  }
  return item;
};

const getQueryArgs = (args) => {
  const searchParams = new URLSearchParams();
  Object.keys(args).forEach((k) => {
    const value = convertToQueryValue(args[k]);
    if (value instanceof Array) {
      value.forEach((item) => {
        searchParams.append(k, convertToQueryValue(item));
      });
    } else if (value !== null && value !== "") {
      searchParams.append(k, value);
    }
  });
  const queryStr = searchParams.toString();
  if (queryStr) {
    return `?${queryStr}`;
  }
  return "";
};

const stripDecimals = (source) => {
  // console.log('stripDecimals', source, typeof source)
  let s = source.toString();
  if (s.endsWith(".00")) {
    return s.substring(0, s.length - 3);
  }
  if (!s.includes(".")) {
    return s;
  }
  if (source) {
    s = toNumber(source).toFixed(2).toString();
  }
  return s;
};

const formatPrice = (currencySign, price, showDecimals) => {
  try {
    if (price <= 0.0001) {
      return "Free";
    }
  } catch (Error) {}
  if (showDecimals) {
    return `${currencySign}${toNumber(price).toFixed(2)}`;
  }
  return `${currencySign}${stripDecimals(price)}`;
};

const forceFloat = (value) => {
  return Number(value);
};

export { getQueryArgs, stripDecimals, formatPrice, forceFloat };
