import "vite/modulepreload-polyfill";
import React from "react";
import { createRoot } from "react-dom/client";
import { init as SentryInit } from "@sentry/react";
import "./index.css";
// import "bootstrap/dist/js/bootstrap";
import {
  ArmoryHolsterFinder,
  BannerHolsterFinder,
  HomeHolsterFinder,
  MenuHolsterFinder,
} from "./apps/HolsterFinder";
import "./assets/scss/main.scss";
import AppWrapper from "./AppWrapper.jsx";
import { postData } from "./api/api";
import "./vendor/swiper-hack/swiper-bundle.css";
import installHandlers from "./jqinit";
import installBootstrapHandlers from "./bsinit";
import reportWebVitals from "./webVitals.js";
import CountdownApp from "./apps/CountdownApp.jsx";
import "./klaviyo.js";
import IconTest from "./apps/IconTest.tsx";

const ReviewApp = React.lazy(() => import("./apps/ReviewApp"));
const TopPaginationHeader = React.lazy(() => import("./apps/Sidebar/Header"));
const Sidebar = React.lazy(() => import("./apps/Sidebar"));
const ShoppingCartApp = React.lazy(() => import("./apps/ShoppingCartApp"));
const CheckoutApp = React.lazy(() => import("./apps/Checkout"));
const ProfileApp = React.lazy(() => import("./apps/Profile"));
const PageSelectorApp = React.lazy(() => import("./apps/PageSelector"));
const GDPRConsentApp = React.lazy(() => import("./apps/GDPRConsent"));

const ProductDetailApp = React.lazy(() => import("./apps/ProductDetailApp"));

const conditionalRenderWithConfig = (Component, rootElement) => {
  const e = document.getElementById(rootElement);
  if (e) {
    const root = createRoot(e);
    root.render(<AppWrapper Component={Component} rootElement={rootElement} />);
  }
};

const conditionalRenderRaw = (Component, rootElement) => {
  const e = document.getElementById(rootElement);
  if (e) {
    const root = createRoot(e);
    root.render(<Component {...e.dataset} />);
  }
};

if (!import.meta.env.DEV) {
  SentryInit({
    dsn: "https://2e2bd0305550481da5590b01616a38ed@o381423.ingest.sentry.io/6004514",
    ignoreErrors: [
      "NetworkError",
      "setIOSParameters",
      "nonIterableSpread",
      "ChunkLoadError",
      "CanceledError",
    ],
    // integrations: [new Integrations.BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    // tracesSampleRate: 0.00001,
  });
}

conditionalRenderWithConfig(MenuHolsterFinder, "root-menu-holsterfinder");
conditionalRenderWithConfig(ArmoryHolsterFinder, "root-armory-holsterfinder");
conditionalRenderWithConfig(ProductDetailApp, "root-product-details");
conditionalRenderWithConfig(ReviewApp, "root-product-reviews");
conditionalRenderWithConfig(HomeHolsterFinder, "root-home-holsterfinder");
conditionalRenderWithConfig(BannerHolsterFinder, "root-banner-holsterfinder");
conditionalRenderWithConfig(TopPaginationHeader, "root-pagination-header");
conditionalRenderWithConfig(Sidebar, "root-filter-sidebar");
conditionalRenderWithConfig(ShoppingCartApp, "root-shopping-cart");
conditionalRenderWithConfig(CheckoutApp, "root-checkout");
conditionalRenderWithConfig(ProfileApp, "root-profile");
conditionalRenderWithConfig(PageSelectorApp, "root-page-selector");
conditionalRenderWithConfig(GDPRConsentApp, "root-gdpr-consent");
conditionalRenderRaw(CountdownApp, "root-countdown");
conditionalRenderRaw(IconTest, "root");

window.postData = postData;

installHandlers();
installBootstrapHandlers();

if (import.meta.env.DEV) {
  reportWebVitals(console.log);
}
